<template>
<div class="container">
  <video-bg
      class="vid"
      :sources="[typingVideo]"
      :img="backImg">
      <div class="row-cols-2 d-flex justify-content-center">
        <div class="main-info">
          <p class="large-text">
            ООО "PRUSSO"
          </p>
          <p class="big-text">
            Pretty Useful Software:<br>
            Мы делаем сложное простым
          </p>
          <br>
        </div>
      </div>
      <div class="row-cols-2 d-flex justify-content-center">
        <button class="btn-color btn margin-top large-btn"
                @click="this.$router.push({name: 'about' });">
          <h2 style="color: white">О нас</h2>
        </button>
      </div>
  </video-bg>
  <br>
  <br>
  <div class="news">
    <h5>Новости:</h5>
    <div class="row-cols-1 d-flex justify-content-center">
      <div>
        <p>На данный момент компания активно занимается разработкой Patent Generator.</p>
      </div>
    </div>
    <div class="row-cols-1 d-flex justify-content-center">
      <a class="btn-color btn large-btn" href="https://patgen.ru" target="_blank">
        <h2 style="color: white">Протестировать продукт!</h2>
      </a>
    </div>
  </div>
  <br>
  <br>

</div>
</template>

<script>
import VideoBg from 'vue-videobg'
import {mapActions} from "vuex";
export default {
  name: "HomeView",
  components: {
    VideoBg
  },
  data() {
    return {
      typingVideo: require('../assets/typing.mp4'),
      backImg: require('../assets/main_background.png')
    }
  },
  mounted () {
    this.changePositionRelative()
  },
  unmounted () {
    this.changePositionFixed()
  },
  methods: {
    ...mapActions([
      'changePositionRelative',
      'changePositionFixed'
    ])
  },
}
</script>

<style scoped>
  .news {
    border-width: 2px;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
  }
  .main-info {
    background-color: rgba(245, 245, 245, 0.3);
    margin-top: 100px;
    border-radius: 10px;
    text-align: center;
  }
  .large-text {
    font-size: 50px;
    font-weight: bold;
  }
  .big-text {
    font-size: 25px;
    font-weight: bold;
  }
  .margin-top {
    margin-top: 100px;
  }
  .large-btn {
    height: 60px !important;
  }
</style>
