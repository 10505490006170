import { createStore } from 'vuex'
import login from './modules/login'

export default createStore({
  state: {
    footerStyle: {
      left: 0, 
      right: 0,
      bottom: 0, 
      zIndex: 10,
      position: 'fixed'
    },
    routerClass: "main-content",
  },
  getters: {
  },
  mutations: {
    savePosition (state, newPos) {
      state.footerStyle.position = newPos
    },
    saveRouterClass (state, newClass) {
      state.routerClass = newClass
    }
  },
  actions: {
    changePositionRelative ( { state, commit } ) {
      commit('savePosition', 'relative')
      commit('saveRouterClass', '')
    },
    changePositionFixed ( {state, commit} ) {
      commit('savePosition', 'fixed')
      commit('saveRouterClass', 'main-content')
    }
  },
  modules: {
  }
})
