<template>
  <nav class="navbar navbar-expand-lg bg-dark">
    <a class="nav-link">
      <router-link class="text-white" to="/">
        <img src="./assets/logo1.png">PRUSSO
      </router-link>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
      <ul class="navbar-nav" style="margin-left: -7%">
        <li class="nav-item">
          <a class="nav-link"><router-link class="text-white" to="/about">О нас</router-link></a>
        </li>
        <li class="nav-item">
          <a class="nav-link">
            <router-link class="text-white" to="/products">Продукты</router-link>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link"><router-link class="text-white" to="/help">Поддержка</router-link></a>
        </li>
        <li class="nav-item">
          <a class="nav-link"><router-link class="text-white" to="/contacts">Контакты</router-link></a>
        </li>
      </ul>
    </div>
  </nav>
  <router-view v-slot="{ Component }" :class="routerClass">
    <transition name="fade">
      <component :is="Component" />
    </transition>
  </router-view>
  <!-- Footer -->
  <footer class="page-footer font-small teal bg-dark text-white" :style="footerStyle">
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">© 2024 Copyright:
      <router-link class="text-white" to="/about">ООО "PRUSSO"</router-link>
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFToken";
export default {
  data() {
    return {
      scrollHeight: 0
    }
  },
  computed: {
    ...mapState({
      footerStyle: state => state.footerStyle,
      routerClass: state => state.routerClass
    })
  },
}
</script>

<style>
/*
html body {
  background-image: url('./assets/bg.jpg');
  background-size: cover;
  background-attachment: fixed;
}
*/
html body {
  background-color: #CCCCCC;
  height: 100vh;
}

.font-20 {
  font-size: 20px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .7s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.btn-color {
  background-color: #702ed6 !important;
}

.btn-color:hover {
  background-color: #999999 !important;
  transition: border-radius 3s, height 5s ease;
  border-radius: 120px;
  height: 40px;
}

.main-content {
  height: 100vh;
}
</style>

